import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";

export default {
    fillable_by:FillableBy.Evaluator,
    title:
        "Clinical Competency Assessment Checklist for Certified Clinical Perfusionist (CCP)",
    sections: [
        {
            title: "I. General Competencies",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Demonstrates comprehensive knowledge of cardiovascular anatomy and physiology.",
                        "Understands perfusion equipment functionality and troubleshooting.",
                        "Knowledge of pharmacological agents used during perfusion.",
                        "Effective communication with the surgical team.",
                        "Provides clear and concise patient updates.",
                        "Demonstrates professionalism in interactions with colleagues and patients.",
                        "Adheres to The Joint Commission and hospital policies.",
                        "Maintains HIPAA compliance and patient confidentiality.",
                        "Keeps certifications (e.g., CCP, BLS, ACLS) up to date.",
                        "Knowledge of infection control measures (e.g., hand hygiene).",
                        "Applies patient safety checks and protocols consistently.",
                        "Adheres to equipment sterilization and maintenance protocols.",
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "II. Preoperative Phase",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Reviews patient history and preoperative diagnostic information.",
                        "Identifies patient-specific perfusion needs and risks.",
                        "Assembles and calibrates the heart-lung machine.",
                        "Prepares perfusion circuit (e.g., tubing, oxygenator, filters).",
                        "Conducts safety checks (e.g., alarm systems, battery backup).",
                        "Develops a tailored perfusion strategy.",
                        "Reviews perfusion plan with the surgical team."
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "III. Intraoperative Phase",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Initiates and maintains cardiopulmonary bypass (CPB).",
                        "Monitors and adjusts flow rates, pressures, and temperatures.",
                        "Administers medications (e.g., anticoagulants, vasoactive agents).",
                        "Quickly identifies and resolves perfusion-related issues.",
                        "Utilizes alternative strategies during equipment failure or emergencies.",
                        "Analyzes blood gases, electrolytes, and hematocrit levels.",
                        "Monitors patient hemodynamics and adjusts parameters as needed.",
                        "Coordinates with the anesthesiologist and surgeon during critical events.",
                        "Provides real-time updates on patient status."
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "IV. Postoperative Phase",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Gradually reduces CPB support while monitoring patient stability.",
                        "Ensures effective myocardial and lung recovery.",
                        "Safely removes and disposes of disposable components.",
                        "Cleans and maintains equipment post-procedure",
                        "Completes the perfusion record with accurate data and observations.",
                        "Documents any deviations from the protocol or adverse events."
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "V. Emergency Preparedness",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Demonstrates competency in managing emergencies (e.g., air embolism, circuit failure)",
                        "Operates backup equipment efficiently.",
                        "Knowledge of institutional emergency response protocols.",
                        "Participates in drills and competency training for emergencies."
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "VI. Continuing Education and Self-Improvement",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Engages in ongoing training and certification courses.",
                        "Participates in professional conferences or workshops.",
                        "Receives and incorporates feedback from peers and supervisors",
                        "Participates in peer reviews or audits."
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "VII. Evaluation Method",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                            "Direct supervision during procedures",
                            "Real-time assessment of decision-making and technical skills.",
                            "Completes simulated scenarios for rare or complex cases.",
                            "Passes written or oral exams on relevant topics",
                            "Submits a personal reflection or self-evaluation on performance."
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "Signatures",
            type: "signature",
            fields:getSignatures(FillableBy.Evaluator)
          },
    ]
}